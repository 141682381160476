<template>
    <div>
        <b-card
            :title="'Ajouter un ' + (gestionnaire ? 'gestionnaire' : 'stagiaire')"
            :sub-title="'Veuillez remplir le formulaire pour ajouter un ' + (gestionnaire ? 'gestionnaire' : 'stagiaire')"
        >
            <form ref="form" class="gutter-t" @submit.stop.prevent="onSubmit">
                <b-form-group
                    label="Nom *"
                    label-for="lastname"
                    class="mb-2"
                    invalid-feedback="Veuillez saisir un nom de famille"
                    :state="validateState('lastname')"
                >
                    <b-form-input
                        id="lastname"
                        name="lastname"
                        :class="$v.form.lastname.$model !== null && $v.form.lastname.$model !== '' ? 'toUpperCase' : ''"
                        v-model="$v.form.lastname.$model"
                        type="text"
                        :state="validateState('lastname')"
                        placeholder="Entrer le nom de famille"
                    />
                </b-form-group>
                <b-form-group
                    label="Prénom *"
                    label-for="firstname"
                    class="mb-2"
                    invalid-feedback="Veuillez saisir un prénom"
                    :state="validateState('firstname')"
                >
                    <b-form-input
                        id="firstname"
                        v-model="$v.form.firstname.$model"
                        name="firstname"
                        type="text"
                        :state="validateState('firstname')"
                        placeholder="Entrer le prénom"
                    />
                </b-form-group>
                <b-form-group
                    :label="'Date de naissance ' + (gestionnaire ? '' : '*')"
                    label-for="date_of_birth"
                    class="mb-2"
                    invalid-feedback="Veuillez indiquer un date de naissance valide"
                    :state="validateState('date_of_birth')"
                >
                    <b-form-input
                        id="date_of_birth"
                        name="date_of_birth"
                        v-model="$v.form.date_of_birth.$model"
                        :state="validateState('date_of_birth')"
                        type="date"
                        :required="!gestionnaire"
                    />
                </b-form-group>
                <b-form-group
                    :label="'E-mail ' + (gestionnaire ? '*' : '')"
                    label-for="email"
                    class="mb-2"
                    :invalid-feedback="'Veuillez saisir un addresse mail valide ' + gestionnaire ? '' : ' (facultatif)'"
                    :state="validateState('email')"
                >
                    <b-form-input
                        id="email"
                        name="email"
                        v-model="$v.form.email.$model"
                        type="email"
                        :state="validateState('email')"
                        placeholder="Entrer l'addresse mail"
                    />
                </b-form-group>
                <p class="text-muted italic" v-if="!gestionnaire">
                    L'adresse e-mail (facultatif) permet au stagiaire de recevoir automatiquement ses informations de connexion. Elle lui
                    permet également restaurer son compte de manière autonome en cas de perte de mot de passe.
                </p>
                <b-form-group
                    label="Rattacher le stagiaire à l'établissement :"
                    label-for="customer"
                    class="mb-2"
                    invalid-feedback="Veuillez sélectionner un établissement"
                    :state="validateState('customer')"
                    data-app
                >
                    <CustomVSelect
                        @input="customerSS()"
                        id="customer"
                        :options="customersOptions"
                        name="customer"
                        v-model="$v.form.customer.$model"
                        :state="validateState('customer')"
                        searchable
                        labelSearchPlaceholder="Rechercher un établissement"
                        labelNotFound="Aucun résultat pour"
                        labelTitle="Sélectionner un établissement"
                    />
                </b-form-group>
                <b-form-group
                    v-if="secondary_siteOptions.length !== 0"
                    label="Rattacher le stagiaire au site secondaire :"
                    label-for="secondary_site"
                    class="mb-2"
                    invalid-feedback="Veuillez sélectionner un site secondaire"
                    :state="validateState('secondary_site')"
                >
                    <CustomVSelect
                        id="secondary_site"
                        :options="secondary_siteOptions"
                        name="secondary_site"
                        v-model="$v.form.secondary_site.$model"
                        :required="secondary_siteOptions.length !== 0 ? true : false"
                        :state="validateState('secondary_site')"
                        searchable
                        labelSearchPlaceholder="Rechercher un site secondaire"
                        labelNotFound="Aucun résultat pour"
                        labelTitle="Sélectionner un site secondaire"
                    />
                </b-form-group>
                <b-button variant="primary" type="submit" ref="submit" class="mt-2" :disabled="loading === true"
                    >Créer le {{ gestionnaire ? 'gestionnaire' : 'stagiaire' }}</b-button
                >
            </form>
        </b-card>
    </div>
</template>

<script>
import { email, required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { mapGetters, mapState, mapMutations } from 'vuex';
import { LIST_ALL_CLIENT } from '@/core/services/store/api/client.service';
import { CREATE_TRAINEE } from '@/core/services/store/api/trainee.service';
import { LIST_SECONDARY_SITE } from '@/core/services/store/api/secondary_site.service';
import moment from 'moment';
import VSelect from '@alfsnd/vue-bootstrap-select';

export default {
    name: 'CreateTrainee',
    props: {
        gestionnaire: {
            type: Boolean,
            default: false,
            required: false
        },
        customerList: {
            type: Array,
            default: () => [],
            required: false
        }
    },
    mixins: [validationMixin],
    components: {
        CustomVSelect: VSelect
    },
    data() {
        return {
            loading: false,
            sendingForm: false,
            prevRoute: {},
            customersOptions: [],
            secondary_siteOptions: [],
            form: {
                firstname: null,
                lastname: null,
                email: null,
                date_of_birth: null,
                customer: null,
                customer_id: null,
                secondary_site: null,
                secondary_site_id: null,
                services: [],
                sendMail: !this.gestionnaire
            }
        };
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.prevRoute = from;
        });
    },
    validations() {
        if (this.secondary_siteOptions.length !== 0 && !this.gestionnaire) {
            return {
                form: {
                    lastname: {
                        required
                    },
                    firstname: {
                        required
                    },
                    date_of_birth: {
                        required,
                        validateDateOfBirth: this.validateDateOfBirth
                    },
                    customer: {
                        required
                    },
                    secondary_site: {
                        required
                    },
                    email: {
                        email
                    }
                }
            };
        } else if (this.gestionnaire) {
            return {
                form: {
                    lastname: {
                        required
                    },
                    firstname: {
                        required
                    },
                    date_of_birth: {
                        validateDateOfBirth: this.validateDateOfBirth
                    },
                    customer: {
                        required
                    },
                    email: {
                        required,
                        email
                    },
                    secondary_site: {}
                }
            };
        } else {
            return {
                form: {
                    lastname: {
                        required
                    },
                    firstname: {
                        required
                    },
                    date_of_birth: {
                        required,
                        validateDateOfBirth: this.validateDateOfBirth
                    },
                    customer: {
                        required
                    },
                    email: {
                        email
                    },
                    secondary_site: {}
                }
            };
        }
    },
    mounted() {
        this.getCustomers();
    },
    methods: {
        validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
        validateDateOfBirth() {
            if (this.form.date_of_birth === null) {
                return true;
            }
            const minDate = new Date();
            minDate.setFullYear(minDate.getFullYear() - 101);
            const maxDate = new Date();
            maxDate.setFullYear(maxDate.getFullYear() - 16);

            const selectedDate = new Date(this.form.date_of_birth);

            if (selectedDate < minDate || selectedDate > maxDate) {
                // Date is not within the range of 15 to 100 years
                return false;
            }
            return this.form.date_of_birth === null ? null : true;
        },
        getCustomers() {
            if (!this.gestionnaire) {
                this.$store.dispatch(LIST_ALL_CLIENT).then(() => {
                    this.customers.data &&
                        this.customers.data.forEach((customer) => {
                            this.customersOptions.push({
                                text: customer.name + ' (' + customer.code + ')',
                                value: customer.id
                            });
                        });
                    this.customersOptions.sort((a, b) => a.text.trim().localeCompare(b.text.trim()));
                });
            } else {
                this.customersOptions = this.customerList;
            }
        },
        onSubmit() {
            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }
            // set spinner to submit button
            const submitButton = this.$refs['submit'];
            submitButton.classList.add('spinner', 'spinner-light', 'spinner-right');
            submitButton.setAttribute('disabled', true);

            this.form.role = this.gestionnaire ? 'Gestionnaire' : 'Utilisateur';
            this.form.date_of_birth ? (this.form.date_of_birth = moment(this.form.date_of_birth).format('YYYY-MM-DD')) : null;
            this.form.customer_id = this.form.customer.value;
            if (this.form.secondary_site) {
                this.form.secondary_site_id = this.form.secondary_site.value;
            }
            //ajout du service academy avec le role utilisateur
            let role = this.gestionnaire ? 2 : 3;
            this.form.services.push({
                id: 1,
                role: role,
                offer: null
            });

            setTimeout(() => {
                this.$store
                    .dispatch(CREATE_TRAINEE, this.form)
                    .then(() => {
                        this.$bvToast.toast(`${this.form.firstname} ${this.form.lastname} est maintenant inscrit(e) à la plateforme.`, {
                            title: "L'utilisateur a bien été créé",
                            variant: 'success',
                            solid: true,
                            autoHideDelay: 7000
                        });
                        this.$v.$reset();
                        this.form = {
                            firstname: null,
                            lastname: null,
                            email: null,
                            date_of_birth: null,
                            customer: null,
                            customer_id: null,
                            secondary_site: null,
                            secondary_site_id: null,
                            services: []
                        };
                        this.secondary_siteOptions = [];
                        this.$emit('refresh-table');
                    })
                    .catch((error) => {
                        if (error && error.data) {
                            if (error.data.errors) {
                                //foreach errors, create a toast
                                Object.keys(error.data.errors).forEach((key) => {
                                    this.$bvToast.toast(error.data.errors[key], {
                                        title: 'Erreur',
                                        variant: 'danger',
                                        solid: true,
                                        autoHideDelay: 7000
                                    });
                                });
                            }
                        }
                    })
                    .finally(() => {
                        submitButton.classList.remove('spinner', 'spinner-light', 'spinner-right');
                        submitButton.removeAttribute('disabled');
                    });
            }, 100);
        },
        customerSS() {
            this.loading = true;
            this.$v.form.secondary_site.$model = null;
            // SEARCH THROUGH API IF A CUSTOMER HAVE A SECONDARY_SITE
            this.$store.dispatch(LIST_SECONDARY_SITE, this.$v.form.customer.$model.value).then((result) => {
                this.secondary_siteOptions = [];
                result.data.forEach((secondary_site) => {
                    this.secondary_siteOptions.push({
                        text: secondary_site.site,
                        value: secondary_site.id
                    });
                });
                this.secondary_siteOptions.sort((a, b) => a.text.trim().localeCompare(b.text.trim()));
            });
        }
    },
    computed: {
        ...mapMutations({
            resetError: 'resetError'
        }),
        ...mapGetters({
            currentUser: 'currentUser',
            customers: 'getClients',
            secondary_site: 'getSecondarySite'
        }),
        ...mapState({
            error: (state) => state.intern.error
        })
    },
    watch: {
        secondary_siteOptions() {
            this.loading = false;
        }
    }
};
</script>

<style>
.toUpperCase {
    text-transform: uppercase;
}
</style>
